<template>
  <div>
    <v-form @submit.prevent="save">
      <v-card class="px-2">
        <v-card-title>
          <div class="font-weight-medium">
            <span class="secondary-font">{{
              $t("account.profile.title")
            }}</span>
          </div>
        </v-card-title>
        <v-card-subtitle class="grey--text text--darken-1">{{
          $t("account.profile.description")
        }}</v-card-subtitle>
        <v-card-text class="mt-4">
          <v-text-field
            :label="$t('security.fields.email.title')"
            outlined
            dense
            append-icon="mdi-at"
            :value="$auth.user().email"
            autocomplete="off"
            class="font-weight-bold"
            readonly
          ></v-text-field>
          <v-text-field
            :label="$t('security.fields.first_name.title')"
            outlined
            dense
            append-icon="mdi-account-tie-outline"
            v-model="form.firstName"
            @input="delayTouch($v.form.firstName)"
            @blur="$v.form.firstName.$touch"
            autocomplete="off"
            :error-messages="firstNameErrors"
            class="font-weight-bold"
          ></v-text-field>
          <v-text-field
            :label="$t('security.fields.last_name.title')"
            outlined
            dense
            append-icon="mdi-account-tie-outline"
            v-model="form.lastName"
            @input="delayTouch($v.form.lastName)"
            @blur="$v.form.lastName.$touch"
            autocomplete="off"
            :error-messages="lastNameErrors"
            class="font-weight-bold"
          ></v-text-field>
          <v-text-field
            :label="$t('security.fields.phone.title')"
            outlined
            dense
            append-icon="mdi-phone-outline"
            v-model="form.phone"
            @input="delayTouch($v.form.phone)"
            @blur="$v.form.phone.$touch"
            autocomplete="off"
            :error-messages="phoneErrors"
            class="font-weight-bold"
            ><template v-slot:append>
              <div style="width: 32px">
                <span
                  class="flag-icon"
                  v-if="countryCode"
                  :class="`flag-icon-${countryCode.toLowerCase()}`"
                  style="font-size: 1.9rem"
                ></span>
                <span v-else><v-icon>mdi-phone-outline</v-icon></span>
              </div>
            </template></v-text-field
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            type="submit"
            :loading="loading.save"
            :disabled="$v.form.$invalid"
            text
            color="primary"
          >
            <span class="font-weight-bold">{{ $t("btn.update") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { required, maxLength, helpers } from "vuelidate/lib/validators";
import FormDelayTouchMixin from "./../../mixins/form-delay-touch";
import FormMixin from "./../../mixins/form";

export default {
  mixins: [FormDelayTouchMixin, FormMixin],
  created() {
    this.initForm();
  },
  data: () => ({
    form: {
      fisrtName: null,
      lastName: null,
      phone: null,
    },
    loading: {
      save: false,
    },
    phoneIsValid: false,
    countryCode: null,
  }),
  validations() {
    return {
      form: {
        firstName: { required, maxLength: maxLength(100) },
        lastName: { required, maxLength: maxLength(100) },
        phone: {
          required,
          matchPhone: (val) => !helpers.req(val) || this.phoneIsValid,
        },
      },
    };
  },
  methods: {
    initForm() {
      const user = this.$auth.user();
      this.form = user.profile;
    },
    getPath() {
      return "/api/profiles";
    },
    getData() {
      return this.form;
    },
    preSubmit() {
      this.loading.save = true;
    },
    postSubmit() {
      this.loading.save = false;
      this.$auth.fetch();
    },
    getMessages() {
      return {
        200: this.$t("account.profile.edit.success"),
        400: true,
        500: true,
      };
    },
  },
  computed: {
    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;

      if (!this.$v.form.firstName.required)
        errors.push(this.$i18n.t("security.fields.first_name.required"));
      if (!this.$v.form.firstName.maxLength)
        errors.push(
          this.$i18n.t("security.fields.first_name.max_length", {
            max: this.$v.form.firstName.$params.maxLength.max,
          })
        );
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;

      if (!this.$v.form.lastName.required)
        errors.push(this.$i18n.t("security.fields.last_name.required"));
      if (!this.$v.form.lastName.maxLength)
        errors.push(
          this.$i18n.t("security.fields.last_name.max_length", {
            max: this.$v.form.lastName.$params.maxLength.max,
          })
        );
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;

      if (!this.$v.form.phone.required)
        errors.push(this.$i18n.t("security.fields.phone.required"));
      if (!this.$v.form.phone.matchPhone)
        errors.push(this.$i18n.t("security.fields.phone.invalid"));
      return errors;
    },
  },
  watch: {
    "form.phone"(phone) {
      this.phoneIsValid = false;
      if (!phone) {
        this.countryCode = null;
      }

      if (phone.startsWith("00")) phone = phone.replace(/^(0){2}/, "");
      if (!phone.startsWith("+")) phone = `+${phone}`;
      const number = this.$awesomenumber(phone);
      this.countryCode = number.getRegionCode();
      if (number.isValid()) {
        this.phoneIsValid = true;
      }
    },
  },
};
</script>