<template>
  <div>
    <profile></profile>
  </div>
</template>


<script>
import Profile from './../../components/account/profile';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('account.profile.title'),
    };
  },
  components: {
    Profile
  }
}
</script>